(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name people.view.controller:ViewCtrl
   *
   * @description
   *
   */
  angular
    .module('people.view')
    .controller('PeopleViewCtrl', PeopleViewCtrl);

  function PeopleViewCtrl($stateParams, Restangular, AddToVehicle) {
    var vm = this;

    vm.addToVehicle = AddToVehicle.add;

    vm.person = {};

    var inc = "Address,VehiclePeople.Vehicle.Event";
    Restangular.one('people', $stateParams.personId).get({inc: inc}).then(function (response) {
      vm.person = response.data;
    });
  }
}());
